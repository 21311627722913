
















import {Vue, Component} from "vue-property-decorator";
import GoPayType from "./GoPay";
import HeadTop from "@/components/ShareComponent/HeadTop.vue"
import ZoomPage from "@/impView/PageSafety";
import GoPayItem from "@/views/Money/GoPay/components/item/index.vue";
import { List } from "@/views/Money/GoPay/model/index";
import { filterList } from "@/views/Money/GoPay/util/index";
import GoPayFooter from "@/views/Money/GoPay/components/footer/index.vue";

@Component({name: "GoPay",components:{ HeadTop,GoPayItem,GoPayFooter }})
export default class GoPay extends ZoomPage implements GoPayType{
    List:any[] = List
    ActiveData:any = {}
    title = "详情"

    mounted(){
        this.loadingShow = false
    }

    activated(){
        let { state } = this.$route.query
        switch (state) {
            case 'shop':
                this.title = "外卖选择支付"
                return;
            case 'exp':
                this.title = "代取选择支付"
                return;
            case 'help':
                this.title = "互助选择支付"
                return;
        }
    }

    handleGetClick(e:any){
        let { data,index } = e
        this.List = filterList(this.List, index)
        this.ActiveData = data
    }


}

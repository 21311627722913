


















import {Vue, Component, Prop, Watch} from "vue-property-decorator";
import GoPayFooterType from "./indexType";
import {Toast} from "vant";
import router from "@/router";

// 定义Ios And Android 路由返回层
try {
    // eslint-disable-next-line
    // @ts-ignore
    window.SYPayBack = function (state = 0,index?: number) {
        Toast.clear()
        let num = 2;
        if (state) {
            Toast({ message:"支付失败", })
            num = 1;
        }else{
            Toast({ message:"支付成功", })
        }
        if (index) num = index;
        router.go(num)
    }
} catch (e) {
    console.info(e)
}

@Component({name: "GoPayFooter"})
export default class GoPayFooter extends Vue implements GoPayFooterType {
    money = "0"
    price = "0.00"
    orderId = ""
    type = ""

    activated() {
        let {money, price, orderId} = this.$route.query
        this.money = money as string
        this.price = price as string || "0"
        this.orderId = orderId as string
        this.initData()
    }

    initData() {
        let {state} = this.$route.query
        let statePay = ""
        switch (state) {
            case "shop":
                this.type = "外卖"
                break
            case "exp":
                this.type = "代取"
                break
            case "help":
                this.type = "互助"
                break
        }
        switch (this.getData && this.getData.state || '') {
            case 'wx':
                statePay = "微信"
                break
            case 'zfb':
                statePay = "支付宝"
                break
            case 'sy':
                statePay = "三易校园"
                break
        }
        let TitleDom: any = document.querySelector("title")
        TitleDom.innerText = this.type + statePay + '支付' || '三易校园'
    }

    handlePay() {
        let {orderId} = this.$route.query
        let phone = navigator.userAgent;
        let isiOS = !!phone.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        let isAndroid = phone.indexOf('Android') > -1 || phone.indexOf('Linux') > -1;
        let wxBool = phone.toLowerCase().match(/MicroMessenger/i)
        let zfbBool = phone.toLowerCase().match(/AlipayClient/i)
        if (wxBool != null && wxBool[0] == "micromessenger") {
            // 微信支付
        } else if (zfbBool != null && zfbBool[0] == 'alipayclient') {
            // 支付宝支付
        } else if (isAndroid) {
            // 安卓支付
        } else if (isiOS) {
            // ios支付
            Toast.loading({ message:"支付中~~~",duration:0 })
            if ("webkit" in window) {
                // eslint-disable-next-line
                // @ts-ignore
                window.webkit.messageHandlers.SYPay.postMessage(
                    {
                        orderId: this.orderId,
                        payType: this.getData && this.getData.state,
                        type: this.type
                    }
                );
            }
        }
    }

    @Prop(Object)
    data!: any

    get getData() {
        return this.data
    }

    @Watch("getData", {deep: true})
    changeData(newVal: any) {
        this.initData()
    }
}

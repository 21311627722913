











import {Vue, Component, Prop, Emit} from "vue-property-decorator";
import GoPayItemType from "./indexType";
import {Toast} from "vant";

@Component({name: "GoPayItem"})
export default class GoPayItem extends Vue implements GoPayItemType{
    ActiveIcon = require("@/assets/icon/Coupon/CardMark.png")

    handleClick(){
        if ( !this.getData.disabled ){
            this.pullClick()
        }else{
            Toast("暂未开通")
        }
    }

    @Emit("pullClick")
    pullClick(){
        return {
            data:this.getData,
            index:this.getIndex
        }
    }

    @Prop(Object)
    data!:any
    get getData(){ return this.data  || {} }
    @Prop(Number)
    index!:number
    get getIndex(){ return this.index }

    get getBorder(){
        if ( this.getIndex && this.getIndex >= 2 )return 0;
        else return 1
    }
}
